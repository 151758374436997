// サイドバー横からスライドするナビゲーションの開閉に関する記述
$(function () {
  $('#nav-toggle').on('click', function() {
    // ヘッダーのメニューボタンを押下でbodyにopenクラスを付け外しする
    $('body').toggleClass('open');
    // サイドバーを開いたら、余白部分を黒いガウスで覆うセレクタを作成する
    $('body').after('<div class="gauss"></div>');
  });
  // サイドバーのxボタン・余白部分を押下でbodyにopenクラス&黒いガウスを外しサイドバーを閉じる
  $(document).on("click", "#close_gloval-nav,.gauss", function(){
    $('body').removeClass('open');
    $('.gauss').remove();
  });
});

// サイドバー内の親子リスト開閉ボタンの実装
$(function () {
  // class="js-open_or_close_button"を押下した場合、隠れていた.children-listが現れる（開いている場合は閉じる）
  $(".js-open_or_close_button").click(function(e) {
    $(this).toggleClass("open");
    $(".children-list").slideToggle();

    // .children-listの各リンクを押下時、開いていたメニューが再び閉じないように命令
    $('.children-list').click(function(e){
      event.stopPropagation();
    });
  });
});
